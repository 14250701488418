<template>
  <div class="data-item">
    <div class="data-item_title">观看数据</div>

    <div class="data-item_content" ref="chartRef"></div>
  </div>
</template>

<script>
import liveJson from "../../../utils/data/live.json";

export default {
  data() {
    return {
      chart: null,
      viewData: liveJson.view,
    };
  },
  mounted() {
    window.addEventListener("resize", this.resizeChart);
  },
  methods: {
    setChartOption() {
      if (!this.$refs.chartRef) return;

      this.chart = this.$echarts.init(this.$refs.chartRef);

      const options = {
        grid: {
          left: "3%",
          right: "4%",
          bottom: "0%",
          top: "10%",
          containLabel: true,
        },
        tooltip: {
          show: true,
          backgroundColor: "transparent",
          borderWidth: 0,
          borderColor: "transparent",
          formatter: function (params) {
            return `
              <div class="tooltip-box">${params.value}人</div>
            `;
          },
        },
        xAxis: {
          type: "category",
          data: this.viewData.category,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          name: "单位: 人",
          nameTextStyle: {
            align: "right",
          },
          type: "value",
          splitLine: {
            lineStyle: {
              color: "rgba(238,238,238,0.1)",
            },
          },
        },
        series: [
          {
            type: "bar",
            stack: "Total",
            silent: true,
            itemStyle: {
              borderColor: "transparent",
              color: "transparent",
            },
            emphasis: {
              itemStyle: {
                borderColor: "transparent",
                color: "transparent",
              },
            },
            data: this.viewData.value[0],
            barWidth: 12,
          },
          {
            type: "bar",
            stack: "Total",
            data: this.viewData.value[1],
            barWidth: 12,
            itemStyle: {
              color: "#00BAFF",
              borderRadius: 6,
            },
          },
        ],
      };

      this.chart.setOption(options);
    },

    resizeChart() {
      if (this.chart) {
        this.chart.resize();
      }
    },
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.resizeChart);
  },
};
</script>

<style lang="scss" scoped>
::v-deep .tooltip-box {
  width: 88px;
  height: 46px;
  background: rgba(24, 32, 55, 0.8);
  box-shadow: inset 0px 0px 95px 2px rgba(16, 108, 222, 0.25), inset 0px 0px 10px 2px rgba(0, 114, 255, 0.8);
  border-radius: 4px 4px 4px 4px;
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
  line-height: 46px;
  text-align: center;
  box-sizing: border-box;
}

.data-item {
  aspect-ratio: 509 / 352;

  &_content {
    margin-top: 20px;
    height: calc(100% - 36px);
  }
}
</style>
