<template>
  <div class="data-item">
    <div class="data-item_title">商品列表</div>

    <div class="data-item_content">
      <div class="content-th flex">
        <div class="flex-item name">商品名称</div>
        <div class="flex-item click">点击率</div>
        <div class="flex-item conversion">转化率</div>
        <div class="flex-item count">销售数量</div>
        <div class="flex-item money">销售金额</div>
      </div>

      <el-scrollbar class="live-goods-data content-tb">
        <div class="goods-empty" v-if="goodsList.length === 0">暂无数据</div>

        <template v-else>
          <div class="goods-item flex" v-for="item in goodsList" :key="item.goods_id">
            <div class="flex-item name">
              <img src="../../../assets/image/othersLive/lucky-bag.png" alt="" />

              <div class="name-label">{{ item.goods_name }}</div>
            </div>
            <div class="flex-item click">{{ item.click_rate }}</div>
            <div class="flex-item conversion">{{ item.change_rate }}</div>
            <div class="flex-item count">{{ item.order_num }}</div>
            <div class="flex-item money">{{ item.sales }}</div>
          </div>
        </template>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { teacherTrainGoodList } from "@/utils/apis.js";

export default {
  data() {
    return {
      pageParam: {
        paging: 1,
        page: 1,
        limit: 50,
        total: 0,
      },
      goodsList: [],
      insertData: false,
    };
  },
  mounted() {
    this.getGoodsList();

    this.$nextTick(() => {
      this.listenerScroll();
    });
  },

  methods: {
    getGoodsList(insert = false) {
      const params = {
        train_id: Number(this.$route.query.id),
        paging: this.pageParam.paging,
        page: this.pageParam.page,
        limit: this.pageParam.limit,
      };

      teacherTrainGoodList(params).then((res) => {
        if (res.code === 200) {
          if (insert) {
            const list = res.data.list || [];
            this.goodsList.push(...list);
          } else {
            this.goodsList = res.data.list || [];
          }

          this.insertData = false;
          this.pageParam.total = res.data.total;
        }
      });
    },

    listenerScroll() {
      const scrollDom = document.querySelector(".live-goods-data.content-tb .el-scrollbar__wrap");

      if (scrollDom) {
        scrollDom.addEventListener("scroll", () => {
          if (
            scrollDom.scrollHeight - scrollDom.clientHeight - scrollDom.scrollTop <= 50 &&
            this.goodsList.length < this.pageParam.total &&
            !this.insertData
          ) {
            this.pageParam.page += 1;

            this.insertData = true;
            this.getGoodsList(true);
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.data-item {
  margin-top: 20px;
  aspect-ratio: 509 / 724;

  &_content {
    margin-top: 20px;
    height: calc(100% - 36px);
    width: 100%;

    .flex {
      display: flex;
      align-items: center;
      padding-inline: 12px;
      gap: 10px;

      .flex-item {
        flex: 1;

        &.name {
          flex: 2;
        }
      }
    }

    .content-th {
      height: 40px;
      background: rgba(129, 178, 255, 0.2);
      border-radius: 4px;

      .flex-item {
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
        text-wrap: nowrap;
      }
    }

    .content-tb {
      height: calc(100% - 42px);
      width: 100%;

      ::v-deep .el-scrollbar__wrap {
        min-width: 0;
        padding-inline: 0;
        margin: 0 !important;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .goods-empty {
        text-align: center;
        padding-top: 150px;
        font-weight: 400;
        font-size: 14px;
        color: #ffffff7e;
        line-height: 16px;
      }

      .goods-item {
        height: 40px;
        background: rgba(80, 104, 144, 0.2);
        border-radius: 4px;
        margin-top: 4px;
        font-size: 12px;

        .flex-item {
          color: rgba(255, 255, 255, 0.8);

          &.name {
            display: flex;
            align-items: center;
            gap: 4px;
            font-size: 11px;

            img {
              width: 30px;
              height: 30px;
              object-fit: cover;
              border-radius: 4px;
            }

            .name-label {
              display: -webkit-box; /* 使用 flexbox 布局来设置多行 */
              -webkit-box-orient: vertical; /* 设置布局的方向为垂直方向 */
              overflow: hidden; /* 隐藏溢出的内容 */
              -webkit-line-clamp: 2; /* 限制显示的行数为 2 */
              text-overflow: ellipsis; /* 超出文本显示省略号 */
              white-space: normal;
            }
          }
        }
      }
    }
  }
}
</style>
