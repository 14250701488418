<template>
  <div class="data-item">
    <div class="data-item_title">各时间段销量</div>

    <div class="data-item_content" ref="chartRef"></div>
  </div>
</template>

<script>
import liveJson from "../../../utils/data/live.json";

export default {
  data() {
    return {
      chart: null,
      timeSalesData: liveJson.timeSales,
    };
  },
  mounted() {
    window.addEventListener("resize", this.resizeChart);
  },
  methods: {
    setChartOption() {
      if (!this.$refs.chartRef) return;

      this.chart = this.$echarts.init(this.$refs.chartRef);

      const options = {
        grid: {
          left: "3%",
          right: "4%",
          bottom: "0%",
          top: "10%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: this.timeSalesData.category,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            lineStyle: {
              color: "rgba(238,238,238,0.1)",
            },
          },
        },
        series: [
          {
            data: this.timeSalesData.value,
            type: "line",
            smooth: true,
            symbol: "none",
            itemStyle: {
              color: "#165DFF",
            },
          },
        ],
      };

      this.chart.setOption(options);
    },

    resizeChart() {
      if (this.chart) {
        this.chart.resize();
      }
    },
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.resizeChart);
  },
};
</script>

<style lang="scss" scoped>
.data-item {
  aspect-ratio: 509 / 352;

  &_content {
    margin-top: 20px;
    height: calc(100% - 36px);
  }
}
</style>
