<template>
  <div class="data-item">
    <div class="data-item_title">人气排行榜</div>

    <div class="data-item_content" ref="chartRef"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      chart: null,
    };
  },

  props: ["liveData"],

  mounted() {
    window.addEventListener("resize", this.resizeChart);
  },

  methods: {
    setChartOption() {
      if (!this.$refs.chartRef) return;

      this.chart = this.$echarts.init(this.$refs.chartRef);

      const category = this.liveData.rank_list.map((item) => item.nickname);
      const chartValue = [];
      const valueKeys = ["online_total_number", "in_online_number", "fans", "barrage_num", "like"];
      const color = {
        in_online_number: "#01f8fd",
        online_total_number: "#2ba4ff",
        fans: "#fd9f20",
        barrage_num: "#182aff",
        like: "#6c39ff",
      };
      valueKeys.forEach((item) => {
        const insertValue = this.liveData.rank_list.map((rank) => rank[item]);
        chartValue.push(insertValue);
      });

      const options = {
        legend: {
          selectedMode: false,
          itemWidth: 8,
          itemHeight: 4,
          borderRadius: 8,
          textStyle: {
            color: "#ffffff",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          top: "10%",
          containLabel: true,
        },
        tooltip: {
          show: true,
          trigger: "axis",
          backgroundColor: "transparent",
          borderWidth: 0,
          borderColor: "transparent",
          formatter: (params) => {
            const nickname = params[0].name;

            let tooltipBoxStr = `
              <div class="tooltip-box">
                <div class="tooltip-box_user">${nickname}</div>
                <div class="tooltip-box_list">
            `;

            params.slice(1).forEach((item) => {
              tooltipBoxStr += `
                <div class="tooltip-item">
                  <div class="item-label">
                    <i class="icon" style="background: ${item.color}"></i>
                    <span>${item.seriesName}</span>
                  </div>

                  <div class="item-value">${item.value}</div>
                </div>
              `;
            });

            tooltipBoxStr += "</div></div>";

            return tooltipBoxStr;
          },
        },
        xAxis: {
          type: "value",
          splitLine: {
            lineStyle: {
              color: "rgba(238,238,238,0.1)",
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(238,238,238,0.5)",
            },
          },
        },
        yAxis: {
          type: "category",
          data: category,
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(238,238,238,0.5)",
            },
          },
        },
        series: [
          {
            type: "bar",
            stack: "total",
            silent: true,
            itemStyle: {
              borderColor: "transparent",
              color: "transparent",
            },
            emphasis: {
              itemStyle: {
                borderColor: "transparent",
                color: "transparent",
              },
            },
            data: new Array(7).fill(50),
            barWidth: 20,
          },
          {
            name: "实时在线数",
            type: "bar",
            stack: "total",
            data: chartValue[0],
            barWidth: 20,
            itemStyle: {
              borderRadius: 2,
              color: color.in_online_number,
            },
          },
          {
            name: "累计观看数",
            type: "bar",
            stack: "total",
            data: chartValue[1],
            barWidth: 20,
            itemStyle: {
              borderRadius: 2,
              color: color.online_total_number,
            },
          },
          {
            name: "新增粉丝数",
            type: "bar",
            stack: "total",
            data: chartValue[2],
            barWidth: 20,
            itemStyle: {
              borderRadius: 2,
              color: color.fans,
            },
          },
          {
            name: "评论数",
            type: "bar",
            stack: "total",
            data: chartValue[3],
            barWidth: 20,
            itemStyle: {
              borderRadius: 2,
              color: color.barrage_num,
            },
          },
          {
            name: "获赞数",
            type: "bar",
            stack: "total",
            data: chartValue[4],
            barWidth: 20,
            itemStyle: {
              borderRadius: 2,
              color: color.like,
            },
          },
        ],
      };

      this.chart.setOption(options);
    },

    resizeChart() {
      if (this.chart) {
        this.chart.resize();
      }
    },
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.resizeChart);
  },
};
</script>

<style lang="scss" scoped>
::v-deep .tooltip-box {
  width: 151px;
  // height: 135px;
  background: rgba(24, 32, 55, 0.8);
  box-shadow: inset 0px 0px 95px 2px rgba(16, 108, 222, 0.25), inset 0px 0px 10px 2px rgba(0, 114, 255, 0.8);
  border-radius: 4px 4px 4px 4px;
  box-sizing: border-box;
  padding: 10px;

  .tooltip-box_user {
    font-weight: 500;
    font-size: 12px;
    color: #ffffff;
  }

  .tooltip-box_list {
    .tooltip-item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & + .tooltip-item {
        margin-top: 2px;
      }

      .item-label {
        display: flex;
        align-items: center;
        gap: 4px;

        i {
          display: block;
          width: 8px;
          height: 4px;
          border-radius: 2px 2px 2px 2px;
        }

        span {
          font-weight: 400;
          font-size: 12px;
          color: #ffffff;
        }
      }

      .item-value {
        font-weight: 400;
        font-size: 12px;
        color: #ffffff;
      }
    }
  }
}

.data-item {
  margin-top: 20px;
  aspect-ratio: 509 / 352;

  &_content {
    margin-top: 20px;
    height: calc(100% - 36px);
  }
}
</style>
