<template>
  <div class="live-video-data">
    <div class="live-video-data_title">
      <span>直播列表</span>

      <div class="show-all" @click="handleShowAll">查看全部>></div>
    </div>

    <div class="live-video-data_content" v-if="!isRefresh">
      <div class="video-item" v-for="item in videoData" :key="item.id">
        <div class="video-item_play" @click="handleLiveDetail(item)">
          <div class="play-status no-start" v-if="!item.getStudentInfo.getOnliveRoom">
            <!-- <span>未开始</span> -->
          </div>

          <div
            :class="[
              'play-status',
              liveState[item.getStudentInfo.getOnliveRoom.status].class,
              { disconnect: item.getStudentInfo.getOnliveRoom.is_disconnect === 1 },
            ]"
            v-else
          >
            <i
              class="iconfont"
              v-if="item.getStudentInfo.getOnliveRoom.status === 1 && item.getStudentInfo.getOnliveRoom.is_disconnect === 0"
            >
              &#xe620;
            </i>
            <i class="iconfont" v-else-if="item.getStudentInfo.getOnliveRoom.status === 2">&#xe63f;</i>

            <span v-if="item.getStudentInfo.getOnliveRoom.status === 1 && item.getStudentInfo.getOnliveRoom.is_disconnect === 1">
              掉线中...
            </span>
            <span v-else>{{ liveState[item.getStudentInfo.getOnliveRoom.status].label }}</span>
          </div>

          <!-- 未创建直播间 -->
          <img src="../../../assets/image/default-live-no-start-bg.png" alt="" v-if="!item.getStudentInfo.getOnliveRoom" />

          <!-- 直播回放 -->
          <img :src="getLiveRoomImg(item.getStudentInfo)" alt="" v-else-if="item.getStudentInfo.getOnliveRoom.status === 2" />

          <!-- 直播暂停 -->
          <img src="../../../assets/image/default-live-pause.png" alt="" v-else-if="item.getStudentInfo.getOnliveRoom.status === 3" />

          <!-- 未开播 -->
          <img :src="item.getStudentInfo.getOnliveRoom.live_background" alt="" v-else-if="item.getStudentInfo.getOnliveRoom.status === 0" />

          <!-- 掉线 -->
          <img
            src="../../../assets/image/default-live-disconnection.png"
            alt=""
            v-else-if="item.getStudentInfo.getOnliveRoom.is_disconnect === 1"
          />

          <!-- 直播中 -->
          <video
            :id="`live_${item.getStudentInfo.getLiveUrl[0].user_id}_${timeStamp}`"
            muted
            autoplay
            loop
            v-else-if="item.getStudentInfo.getOnliveRoom.status === 1"
          ></video>
        </div>

        <div class="video-item_title">
          {{ item.getStudentInfo.getOnliveRoom ? item.getStudentInfo.getOnliveRoom.title : "" }}
        </div>

        <div class="video-item_user">
          <template v-if="item.getStudentInfo.getOnliveRoom">
            <div class="user-info">
              <img :src="getUserImg(item.getStudentInfo.getOnliveRoom.avatar)" alt="" />
              <div class="user-info-name">{{ item.getStudentInfo.getOnliveRoom.nickname }}</div>
            </div>
          </template>

          <template v-else>
            <div class="user-info">
              <img :src="getUserImg(item.avatar)" alt="" />
              <div class="user-info-name">{{ item.getStudentInfo.nickname }}</div>
            </div>
          </template>

          <div class="user-hot">
            <i class="iconfont">&#xe66a;</i>
            <span>
              {{ item.getStudentInfo.getOnliveRoom && item.getStudentInfo.getOnliveRoom.status !== 0 ? getRandomValue() : 0 }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { teacherTrainRoomList, getOtherLive } from "@/utils/apis";

export default {
  data() {
    return {
      videoData: [],
      liveState: {
        0: { label: "未开播", class: "no-aired" },
        1: { label: "直播中...", class: "pending" },
        2: { label: "直播回放", class: "over" },
        3: { label: "直播暂停", class: "pause" },
      },
      timeStamp: new Date().getTime(),
      isRefresh: false,
    };
  },
  mounted() {
    this.getLiveVideoData();
  },
  methods: {
    getLiveVideoData() {
      const params = {
        train_id: this.$route.query.id,
        page: 1,
        limit: 6,
      };

      teacherTrainRoomList(params)
        .then((res) => {
          if (res.code == 200) {
            this.videoData = res.data.list || [];
            this.isRefresh = false;

            this.$nextTick(() => {
              this.showLiveVideo();
            });
          }
        })
        .catch((err) => {
          console.error("err: ", err);
        });
    },

    showLiveVideo() {
      const pengdingLive = this.videoData.filter((item) => {
        if (!item.getStudentInfo.getOnliveRoom) return false;

        return item.getStudentInfo.getOnliveRoom.status === 1 && item.getStudentInfo.getOnliveRoom.is_disconnect === 0;
      });

      pengdingLive.forEach((item) => {
        const idName = `live_${item.getStudentInfo.getLiveUrl[0].user_id}_${this.timeStamp}`;
        const webRTC = item.getStudentInfo.getLiveUrl[0].webrtc_rtmp;
        const m3u8Str = item.getStudentInfo.getLiveUrl[0].http_two_rtmp;

        const player = TCPlayer(idName, {
          sources: [
            {
              // 快直播地址
              src: webRTC,
            },
            {
              // HLS直播地址
              src: m3u8Str,
            },
          ],
          licenseUrl: "https://license.vod2.myqcloud.com/license/v2/1305294767_1/v_cube.license",
          autoplay: true,
          poster: item.getStudentInfo.getOnliveRoom.live_background,
          controls: false,
        });

        player.on("error", (err) => {
          console.log(err);
          if (!this.isRefresh) {
            this.isRefresh = true;
            this.timeStamp = new Date().getTime();
            this.getLiveVideoData();
          }
        });

        this.$nextTick(() => {
          player.play();
        });
      });
    },

    handleShowAll() {
      this.$router.push({
        path: "/liveData/all",
        query: {
          id: this.$route.query.id,
          end_time: this.$route.query.end_time,
        },
      });
    },

    getUserImg(avatar) {
      return avatar ? avatar : require("assets/image/blank_headPic.png");
    },

    getRandomValue() {
      const hotValue = Math.random() * 400 + 100;

      return `${hotValue.toFixed(2)}w`;
    },

    async handleLiveDetail(row) {
      console.log("live detail row: ", row);
      let url = "";
      const liveStatus = row.getStudentInfo.getOnliveRoom ? row.getStudentInfo.getOnliveRoom.status : -1;

      if ([-1, 0].includes(liveStatus)) {
        // 未创建直播间 ,  未开播
        url = this.$router.resolve({
          path: "/liveData/no-started",
          query: {
            id: this.$route.query.id,
            end_time: this.$route.query.end_time,
            nickname: row.getStudentInfo.getOnliveRoom ? row.getStudentInfo.getOnliveRoom.nickname : row.getStudentInfo.nickname,
            avatar: row.getStudentInfo.getOnliveRoom ? row.getStudentInfo.getOnliveRoom.avatar : row.avatar,
            room_id: row.getStudentInfo.getOnliveRoom ? row.getStudentInfo.getOnliveRoom.id : 0,
          },
        }).href;
      } else if ([1, 3].includes(liveStatus)) {
        // 直播中，直播暂停
        const trainInfo = {
          advert_id: null,
          script_id: null,
        };

        const res = await getOtherLive({
          train_id: this.$route.query.id,
          paging: 1,
          page: 1,
          pageSize: 1,
        });

        if (res.code === 200) {
          if (res.data && res.data.list && res.data.list.length > 0) {
            trainInfo.advert_id = res.data.list[0].train_info.advert_id;
            trainInfo.script_id = res.data.list[0].train_info.script_id;
          }
        }

        // 直播中
        url = this.$router.resolve({
          path: "/liveData/detail",
          query: {
            roomId: row.getStudentInfo.getOnliveRoom.id,
            userId: row.getStudentInfo.id,
            trainId: this.$route.query.id,
            advertId: trainInfo.advert_id,
            script_id: trainInfo.script_id,
            end_time: this.$route.query.end_time,
            webrtc: row.getStudentInfo.getLiveUrl[0].webrtc_rtmp,
            m3u8: row.getStudentInfo.getLiveUrl[0].http_two_rtmp,
          },
        }).href;
      } else if (liveStatus === 2) {
        // 直播回放
      }

      if (!!url) window.open(url, "_blank");
    },

    getLiveRoomImg(studentInfo) {
      const pic = studentInfo.getLiveUrl[0].pic;

      return pic ? pic : studentInfo.getOnliveRoom.live_background;
    },
  },
};
</script>

<style lang="scss" scoped>
.live-video-data {
  margin-top: 20px;
  aspect-ratio: 741 / 847;
  border-radius: 8px;
  // padding: 20px;
  box-sizing: border-box;

  &_title {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
    line-height: 16px;
    position: relative;

    &::before {
      content: "";
      display: inline-block;
      background: url("../../../assets/image/tag.png");
      background-repeat: no-repeat;
      width: 31px;
      height: 11px;
      margin-right: 6px;
    }

    .show-all {
      position: absolute;
      font-weight: 400;
      font-size: 16px;
      color: #81839a;
      top: 50%;
      right: 0%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }

  &_content {
    margin-top: 20px;
    height: calc(100% - 36px);
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .video-item {
      flex-shrink: 0;
      width: calc((100% - 40px) / 3);
      height: calc((100% - 20px) / 2);
      border-radius: 8px;
      background-color: #0c0e3f;
      display: flex;
      flex-direction: column;
      gap: 10px;

      &_play {
        background-color: #ffffff27;
        height: calc(100% - 69px);
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        cursor: pointer;

        .play-status {
          position: absolute;
          top: 0;
          right: 0;
          padding: 2px 6px;
          font-weight: 400;
          font-size: 12px;
          color: #ffffff;
          z-index: 999;

          .iconfont {
            font-size: 12px;
            margin-right: 2px;
          }

          &.pending,
          &.pause {
            background-color: #4741ff99;
          }

          &.no-aired,
          &.no-start,
          &.disconnect {
            background-color: #e2e2e299;
          }

          &.over {
            background-color: #ff414498;
          }
        }

        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        ::v-deep .video-js {
          width: 100%;
          height: 100%;
        }
      }

      &_title {
        padding-inline: 10px;
        height: 19px;
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
        white-space: nowrap; /* 防止文本换行 */
        overflow: hidden; /* 隐藏超出容器的部分 */
        text-overflow: ellipsis; /* 超出部分显示省略号 */
      }

      &_user {
        height: 20px;
        font-weight: 400;
        font-size: 12px;
        color: #ffffff;
        padding-inline: 10px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        .user-info,
        .user-hot {
          display: flex;
          align-items: center;
          gap: 4px;
        }

        .user-info {
          width: 60%;

          img {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            object-fit: cover;
          }

          .user-info-name {
            width: calc(100% - 24px);
            white-space: nowrap; /* 防止文本换行 */
            overflow: hidden; /* 隐藏超出容器的部分 */
            text-overflow: ellipsis; /* 超出部分显示省略号 */
          }
        }

        .user-hot {
          .iconfont {
            color: #19fdff;
          }
        }
      }
    }
  }
}
</style>
