<template>
  <el-scrollbar class="live-data-screen">
    <div class="live-data-screen_header">电商数据主屏</div>

    <div class="live-data-screen_box">
      <LiveTopData :liveData="liveData" ref="liveTopRef"></LiveTopData>

      <div class="box-bottom">
        <div class="box-bottom_left">
          <!-- 观看数据 -->
          <LiveViewData ref="liveViewRef"></LiveViewData>

          <!-- 商品列表 -->
          <LiveGoodsData></LiveGoodsData>
        </div>

        <div class="box-bottom_center">
          <!-- 直播销售情况 -->
          <LiveSalesData :liveData="liveData"></LiveSalesData>

          <!-- 直播列表 -->
          <LiveVideoData></LiveVideoData>
        </div>

        <div class="box-bottom_right">
          <!-- 各时间端销量 -->
          <LiveTimeSalesData ref="liveTimeSalesRef"></LiveTimeSalesData>

          <!-- 人气排行榜 -->
          <LivePopularityData :liveData="liveData" ref="livePopularityRef"></LivePopularityData>

          <!-- 学生销量排行 -->
          <LiveStudentSalesData :liveData="liveData" ref="liveStudentSalesRef"></LiveStudentSalesData>
        </div>
      </div>
    </div>
  </el-scrollbar>
</template>

<script>
import LiveTopData from "./modules/LiveTopData.vue";
import LiveViewData from "./modules/LiveViewData.vue";
import LiveGoodsData from "./modules/LiveGoodsData.vue";
import LiveTimeSalesData from "./modules/LiveTimeSalesData.vue";
import LivePopularityData from "./modules/LivePopularityData.vue";
import LiveStudentSalesData from "./modules/LiveStudentSalesData.vue";
import LiveSalesData from "./modules/LiveSalesData.vue";
import LiveVideoData from "./modules/LiveVideoData.vue";
import { teacherTrainLiveData } from "@/utils/apis.js";

export default {
  data() {
    return {
      liveData: {
        total_number: 0, //总观看人数
        barrage_num: 0, //弹幕数量
        sales: 0, //销售额
        order_num: 0, //销量
        like_num: 0, //点赞数量
        fans_num: 0, //涨粉人数
        goods_shelves_num: 0, //上架商品数
        max_online_num: "0", //人气峰值
        avg_online_num: 0, //平均在线
        unit_price: 0, //客单价
        fans_rate: "0", //涨粉率
        conversion_rate: "0", //带货转化率
        uv_value: 0, //UV价值
        rank_list: [], //人气排行榜
        order_list: [], //销量排行
      },
    };
  },

  created() {
    this.getLiveData();
  },

  mounted() {},

  components: {
    LiveTopData,
    LiveViewData,
    LiveGoodsData,
    LiveTimeSalesData,
    LivePopularityData,
    LiveStudentSalesData,
    LiveSalesData,
    LiveVideoData,
  },

  methods: {
    getLiveData() {
      const params = {
        train_id: Number(this.$route.query.id),
      };
      teacherTrainLiveData(params).then((res) => {
        if (res.code === 200) {
          if (res.data) {
            this.liveData = res.data;

            this.$nextTick(() => {
              this.setData();
            });
          }
        }
      });
    },

    setData() {
      this.$refs.liveTopRef.setData(); // 概览数据设置
      this.$refs.liveStudentSalesRef.setData(); // 学生销量排行数据设置

      this.$refs.liveViewRef.setChartOption();
      this.$refs.liveTimeSalesRef.setChartOption();
      this.$refs.livePopularityRef.setChartOption();
    },
  },
};
</script>

<style lang="scss" scoped>
.live-data-screen {
  background-color: #080b32;
  height: 100vh;
  // overflow-x: auto;

  ::v-deep .el-scrollbar__wrap {
    min-width: 1412px;
    padding-inline: 40px;
    margin: 0 !important;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &_header {
    padding-top: 10px;
    margin-bottom: 40px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 800;
    font-size: 30px;
    color: #ffffff;
    line-height: 30px;
    text-align: center;
  }

  &_box {
    ::v-deep .data-item {
      background-color: #0c0e3f;
      border-radius: 8px;
      padding: 20px;
      box-sizing: border-box;

      &_title {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        line-height: 16px;

        &::before {
          content: "";
          display: inline-block;
          background: url("../../assets/image/tag.png");
          background-repeat: no-repeat;
          width: 31px;
          height: 11px;
          margin-right: 6px;
        }
      }
    }

    .box-bottom {
      display: flex;
      gap: 30px;
      margin-top: 20px;
      padding-bottom: 10px;

      &_left,
      &_right {
        width: 30%;
        flex-shrink: 0;
        flex-grow: 0;
      }

      &_center {
        width: 40%;
      }
    }
  }
}
</style>
