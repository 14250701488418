<template>
  <div class="box-top">
    <!-- 人气数据 -->
    <div class="data-item popularity">
      <div class="data-item_title">人气数据</div>

      <div class="data-item_content">
        <div class="content-top">
          <div class="top-item" v-for="(item, index) in popularityData.overview" :key="index">
            <img src="../../../assets/image/eyes.png" alt="" />

            <div class="item-info">
              <div class="info-label">{{ item.label }}</div>
              <div class="info-value">{{ item.value }}</div>
            </div>
          </div>
        </div>

        <div class="content-split"></div>

        <div class="content-bottom">
          <div class="bottom-item" v-for="(item, index) in popularityData.cumulative" :key="index">
            <span class="item-label">{{ item.label }}: </span>
            <span class="item-value">{{ item.value }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 带货数据 -->
    <div class="data-item bring">
      <div class="data-item_title">带货数据</div>

      <div class="data-item_content">
        <div class="content-top">
          <div class="top-item" v-for="(item, index) in bringData.overview" :key="index">
            <img src="../../../assets/image/eyes.png" alt="" />

            <div class="item-info">
              <div class="info-label">{{ item.label }}</div>
              <div class="info-value">{{ item.value }}</div>
            </div>
          </div>
        </div>

        <div class="content-split"></div>

        <div class="content-bottom">
          <div class="bottom-item" v-for="(item, index) in bringData.cumulative" :key="index">
            <span class="item-label">{{ item.label }}: </span>
            <span class="item-value">{{ item.value }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      popularityData: {
        overview: [
          { label: "总观看人数", value: "0" },
          { label: "人气峰值", value: "0" },
          { label: "平均在线", value: "0" },
          { label: "发送弹幕", value: "0" },
        ],
        cumulative: [
          { label: "累计点赞", value: "0" },
          { label: "涨粉人数", value: "0" },
          { label: "涨粉率", value: "0" },
        ],
      },
      bringData: {
        overview: [
          { label: "本场销售额", value: "0" },
          { label: "销量(件)", value: "0" },
          { label: "客单价", value: "0" },
        ],
        cumulative: [
          { label: "上架商品", value: "0" },
          { label: "带货转化率", value: "0" },
          { label: "uv价值", value: "0" },
        ],
      },
    };
  },
  props: ["liveData"],
  
  mounted() {},

  methods: {
    setData() {
      this.popularityData = {
        overview: [
          { label: "总观看人数", value: this.liveData.total_number },
          { label: "人气峰值", value: this.liveData.max_online_num },
          { label: "平均在线", value: this.liveData.avg_online_num },
          { label: "发送弹幕", value: this.liveData.barrage_num },
        ],
        cumulative: [
          { label: "累计点赞", value: this.liveData.like_num },
          { label: "涨粉人数", value: this.liveData.fans_num },
          { label: "涨粉率", value: this.liveData.fans_rate },
        ],
      };

      this.bringData = {
        overview: [
          { label: "本场销售额", value: this.liveData.sales },
          { label: "销量(件)", value: this.liveData.order_num },
          { label: "客单价", value: this.liveData.unit_price },
        ],
        cumulative: [
          { label: "上架商品", value: this.liveData.goods_shelves_num },
          { label: "带货转化率", value: this.liveData.conversion_rate },
          { label: "uv价值", value: this.liveData.uv_value },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.box-top {
  display: flex;
  gap: 20px;

  .data-item {
    flex: 1;
    height: 240px;

    &_content {
      margin-top: 30px;
      height: 138px;
      background: #1f2a49;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 20px;

      .content-top,
      .content-bottom {
        display: grid;

        .top-item,
        .bottom-item {
          display: flex;
          align-items: center;
        }

        .top-item {
          gap: 17px;

          img {
            width: 48px;
            height: 48px;
            object-fit: cover;
          }

          .item-info {
            .info-label {
              font-weight: 400;
              font-size: 14px;
              color: #7cf3ff;
              line-height: 14px;
            }

            .info-value {
              margin-top: 10px;
              font-weight: 400;
              font-size: 24px;
              color: #ffb469;
              line-height: 24px;
            }
          }
        }

        .bottom-item {
          gap: 4px;

          span {
            font-weight: 400;
            font-size: 12px;
            color: #ffffff;
            line-height: 16px;

            &.item-value {
              color: #ffb469;
            }
          }
        }
      }

      .content-split {
        height: 1px;
        background: #3f4b6a;
        border-radius: 0px 0px 0px 0px;
        margin-block: 20px 12px;
      }
    }

    &.popularity {
      .content-top,
      .content-bottom {
        grid-template-columns: repeat(4, 1fr);
      }
    }

    &.bring {
      .content-top,
      .content-bottom {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}
</style>
